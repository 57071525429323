import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__logos">
        <div className="gestio-logo" />
        <p>Une création</p>
        <div className="opan-logo" />
      </div>
      <div className="Footer__contact">
        <p>
          <b>Contactez-nous:</b>
          <br />
          <br />
          OPAN concept SA
          <br />
          Ruelle Vaucher 22
          <br />
          2002 Neuchâtel
          <br />
          <b>
            <a target="_blank" href="https://www.opan.ch" rel="noreferrer">
              www.opan.ch
            </a>
            <br />
            <a href="mailto:gestio@opan.ch">gestio@opan.ch</a>
          </b>
        </p>
      </div>
      <div className="Footer__madeIn">
        <div className="made-in__wrapper">
          <div className="made-in-ch-logo">
            <span>+</span>
          </div>
          <p>
            Made with{' '}
            <span className="heart" role="img" aria-label="coeur" style={{ color: '#E44233' }}>
              ♥
            </span>{' '}
            <br />
            in Switzerland
          </p>
        </div>
        <p className="copyright">Gestio® | Tous droits réservés | 2024</p>
      </div>
    </footer>
  );
};

export default Footer;

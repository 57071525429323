import React, { useEffect, useState } from 'react';
import './Intro.scss';
import { ArrowDown } from 'react-feather';
import { AnimatePresence, motion } from 'framer-motion';
import SlideShowLauncher from '../../components/SlideShow/SlideShowLauncher';

const railPictureSrc = require('../../img/train_bridge.jpg');
const roadPictureSrc = require('../../img/road_aerial.jpg');
const delemontPictureSrc = require('../../img/delemont_1.jpg');
const lakePictureSrc = require('../../img/road_lake.jpg');

const Intro = () => {
  const scrollToNextSection = () => {
    const element = document.getElementById('News');

    if (element !== null) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <section className="Intro">
      <div className="text-content">
        <h1>
          <span className="lighter">La solution adaptée pour la gestion durable de </span>
          <br />
          <span>votre patrimoine routier ou ferroviaire.</span>
        </h1>
        <p>
          L'écosystème Gestio® est une solution pragmatique et novatrice de gestion du patrimoine,
          développée en Suisse par un bureau d'expertise en génie civil qualifié.
        </p>
        <p>
          Gestio® met à profit les technologies les plus modernes afin d'automatiser et de
          simplifier l'ensemble des tâches liées à la gestion du patrimoine construit.
        </p>

        <div className="intro-slides">
          <SlideShowLauncher
            title="Consulter notre présentation"
            url="/slideshow.pdf"
            showModalButton={false}
          />
        </div>

        <div className="gestio-modules-logos">
          <div className="module-logo logo-rail" />
          <div className="module-logo logo-route" />
        </div>

        <div className="intro-footer">
          <div className="scroll-down-indicator" onClick={scrollToNextSection}>
            <div className="scroll-down-icon">
              <ArrowDown strokeWidth={2} color={'white'} />
            </div>
            <div className="scroll-down-text">
              <span>Faire défiler pour en savoir plus.</span>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-picture-composition">
        <div className="logo-overlay" />
        <PictureSlideshow
          imageSrcs={[lakePictureSrc, railPictureSrc, roadPictureSrc, delemontPictureSrc]}
        />
      </div>
    </section>
  );
};

interface PictureSlideshowProps {
  imageSrcs: string[];
}

export const PictureSlideshow: React.FC<PictureSlideshowProps> = ({ imageSrcs }) => {
  const [currentPictureSrc, setCurrentPictureSrc] = useState(imageSrcs[0]);

  useEffect(() => {
    let currentImageIndex = 1;

    const interval = setInterval(() => {
      setCurrentPictureSrc(imageSrcs[currentImageIndex]);

      if (currentImageIndex < imageSrcs.length - 1) {
        currentImageIndex++;
      } else {
        currentImageIndex = 0;
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [imageSrcs]);

  return (
    <AnimatePresence>
      <motion.img
        className="hero-picture"
        key={currentPictureSrc}
        src={currentPictureSrc}
        initial={{ zIndex: 0, opacity: 0 }}
        animate={{ zIndex: 1, opacity: 1 }}
        exit={{ zIndex: 0, opacity: 0 }}
        transition={{ duration: 3 }}
      />
    </AnimatePresence>
  );
};

export default Intro;

import { useAnimation } from 'framer-motion';
import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import ResizeObs from '../../components/ResizeObs';
import './Videos.scss';

const Videos = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-20% 0px'
  });

  const videoRef = useRef<HTMLVideoElement>(null);

  const controls = useAnimation();

  if (inView) {
    controls.start('visible');
    videoRef.current?.play();
  } else {
    videoRef.current?.pause();
  }

  return (
    <section className="Videos">
      <div className="videos-content" ref={ref}>
        <ResizeObs className="video-container">
          {(width) => (
            <video ref={videoRef} muted loop controls width={width * 0.85}>
              <source src="/gestio_demo.mp4" />
            </video>
          )}
        </ResizeObs>
      </div>
      <div className="videos-illustration">
        <div className="videos-illustration__citation">
          <h2>
            <span className="lighter">Bras numérique de la</span> RTE 29900
          </h2>
        </div>
        <div className="videos-illustration__picture" />
      </div>
    </section>
  );
};

export default Videos;

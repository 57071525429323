import { useRef } from 'react';
import ResizeObs from '../../components/ResizeObs';

import './projection.scss';
import { DownloadCloud } from 'react-feather';

/**
 * TransN componenent
 */
export default function Projection(): JSX.Element {
  /*const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-20% 0px'
  });*/

  const videoRef = useRef<HTMLVideoElement>(null);

  //const controls = useAnimation();

  /*if (inView) {
    controls.start('visible');
    videoRef.current?.play();
  } else {
    videoRef.current?.pause();
  }

  videoRef.current?.play();*/

  return (
    <div className="video-proj-content">
      <ResizeObs className="video-container">
        {(width) => (
          <video ref={videoRef} muted autoPlay loop controls width={width * 1}>
            <source src="/projection.webm" />
          </video>
        )}
      </ResizeObs>
      <div className="card__title">
        Nouveauté 2024: prévision du vieillissement des installations
        <a href="/projection.webm" className="card__download" target="_blank" rel="noreferrer">
          Télécharger la vidéo
          <DownloadCloud />
        </a>
      </div>
    </div>
  );
}

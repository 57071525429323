import { ReactNode, useCallback, useRef, useState } from 'react';

/**
 * ResizeObs componenent properties
 */
export interface ResizeObsProps {
  className?: string;
  children: (width: number, height: number) => ReactNode;
}

/**
 * ResizeObs componenent
 */
export default function ResizeObs({ className, children }: ResizeObsProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>();
  const resizeObserver = useRef<ResizeObserver | undefined>();
  const [bbox, setBbox] = useState({ width: 1, height: 1 });

  const sync = useCallback(() => {
    console.log('Sync Size');
    const bbox = containerRef.current?.getBoundingClientRect();
    if (bbox) {
      setBbox({
        width: bbox.width,
        height: bbox.height
      });
    }
  }, []);

  const setContainerRef = useCallback(
    (container: HTMLDivElement | null) => {
      if (resizeObserver.current != null) {
        resizeObserver.current.disconnect();
      }
      if (container) {
        const ro = new ResizeObserver(() => {
          sync();
        });

        ro.observe(container);
        resizeObserver.current = ro;
      }

      containerRef.current = container || undefined;
    },
    [sync]
  );

  return (
    <div
      className={className}
      style={{
        width: '100%',
        height: '100%'
      }}
      ref={setContainerRef}
    >
      {children(bbox.width, bbox.height)}
    </div>
  );
}

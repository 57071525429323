import './lifecycle.scss';

import { ZoomIn } from 'react-feather';

import image from '../../img/lifecycle.jpg';

/**
 * TransN componenent
 */
export default function Lifecyle(): JSX.Element {
  return (
    <div className="lc-card">
      <a href={image} className="card__download" target="_blank" rel="noreferrer">
        Plein-écran
        <ZoomIn />
      </a>
      <div className="lc-card__title">
        Nouveauté 2024: prévision du vieillissement des installations
      </div>
    </div>
  );
}

import SlideShowLauncher from '../../components/SlideShow/SlideShowLauncher';
import './oft.scss';

/**
 * Mvr componenent
 */
export default function Oft(): JSX.Element {
  return (
    <div className="oft-card">
      <div className="oft-card__launcher">
        <SlideShowLauncher title="Consulter notre présentation" url="/oft.pdf" />
      </div>
      <div className="oft-card__title">
        Le 23 novembre 2022, l’équipe GESTIO participe à la «Session spécialisée – Infrastructure
        ferroviaire» de l’OFT
      </div>
    </div>
  );
}

import { motion, useAnimation } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import './Advantages.scss';

// Animation specs
const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2
    }
  },
  hidden: {
    opacity: 0
  }
};

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -100 }
};

const Advantages = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-20% 0px'
  });

  const controls = useAnimation();

  if (inView) {
    controls.start('visible');
  }

  return (
    <section className="Advantages">
      <div className="advantages-text">
        <h1>
          <span className="lighter">Nos valeurs.</span>
          <br />
          Votre plus-value.
        </h1>
        <div className="advantages-paragraph" ref={ref}>
          <p>
            Le bon fonctionnement des infrastructures est primordial à notre société et notre
            économie. Garantir une gestion efficace, réfléchie et prévoyante de ce patrimoine est
            notre tâche et notre passion. <br />
            <br />
            Né d'une étroite collaboration avec nos clients dans les domaines ferroviaire et
            routier, Gestio combine la longue expérience d'ingéniérie civile du bureau OPAN concept
            SA, avec des technologies web et SIG (systèmes d'information géographique) innovantes.
            La structuration et la centralisation des informations sur l'inventaire, l'état,
            l'historique et de la planification de votre réseau, vous permettront de maîtriser à
            tout moment les différents éléments de votre patrimoine.
          </p>
        </div>
        <motion.div
          className="advantages-icons"
          variants={list}
          initial="hidden"
          animate={controls}
          ref={ref}
        >
          <AdvantageIcon
            title="Sur mesure"
            text="Reprise et intégration de vos données existantes."
            color="#F88360"
          />
          <AdvantageIcon
            title="Expérience"
            text="10 ans d’expérience rassemblés dans un produit accessible, simple et pragmatique."
            color="#2A725F"
          />
          <AdvantageIcon
            title="Interdomaine"
            text="La gestion efficace et durable du patrimoine d'infrastructure repose sur la collaboration et la communication entre tous les acteurs concernés."
            color="#F6B726"
          />
          <AdvantageIcon
            title="Ouverture"
            text="Gestio® repose sur des technologies open-source validées et répandues."
            color="#446CAC"
          />
        </motion.div>
      </div>
      <div className="advantages-illustration">
        <div className="advantages-illustration__citation">
          <h2>
            Apprendre du passé
            <br />
            Maîtriser le présent
            <br />
            Anticiper l'avenir
          </h2>
        </div>
        <div className="advantages-illustration__picture" />
      </div>
    </section>
  );
};

interface AdvantageIconProps {
  title: string;
  text: string;
  color: string;
}

const AdvantageIcon: React.FC<AdvantageIconProps> = ({ title, text, color }) => {
  return (
    <motion.div className="AdvantageIcon" variants={item}>
      <h3 className="AdvantageIcon__title" style={{ color }}>
        {title}
      </h3>
      <div className="AdvantageIcon__separator" style={{ backgroundColor: color }} />
      <p className="AdvantageIcon__text">{text}</p>
    </motion.div>
  );
};

export default Advantages;

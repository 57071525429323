import { DownloadCloud, PlayCircle } from 'react-feather';
import Modal from '../Modal';
import { SlideShow } from './SlideShow';

import './slideshow.scss';

/**
 * SlideShowLauncher componenent properties
 */
export interface SlideShowLauncherProps {
  title: string;
  url: string;
  showModalButton?: boolean;
}

/**
 * SlideShowLauncher componenent
 */
export default function SlideShowLauncher({
  title,
  url,
  showModalButton = false
}: SlideShowLauncherProps): JSX.Element {
  return (
    <div className="slideshow-launcher">
      <a style={{ color: 'var(--orange)' }} href={url} target="_blank" rel="noreferrer">
        {title} <DownloadCloud />
      </a>
      {showModalButton && (
        <Modal
          fullscreen={
            <div className="slideshow-modal">
              <SlideShow
                url={url}
                height={window.innerHeight}
                width={window.innerWidth}
                showToolbar
              />
            </div>
          }
        >
          <PlayCircle color="var(--orange)" style={{ cursor: 'pointer' }} />
        </Modal>
      )}
    </div>
  );
}

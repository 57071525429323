import { useCallback, useRef, useState } from 'react';

import './slideshow.scss';

import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'react-feather';

import { pdfjs } from 'react-pdf';
// import { Document, Page } from "react-pdf";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

/**
 * slideshow componenent properties
 */
export interface SlideShowProps {
  title?: string;
  url: string;
  height: number;
  width: number;
  showToolbar?: boolean;
  fit?: boolean;
}

/**
 * slideshow componenent
 */
export const SlideShow = ({
  title,
  url,
  width,
  height,
  showToolbar,
  fit = true
}: SlideShowProps) => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const [numPages, setNumPages] = useState<number | undefined>();
  const [pageNumber, setPageNumber] = useState(1);

  const [pageSize, setPageSize] = useState<{
    width?: number | undefined;
    height?: number | undefined;
  }>({
    width: width
  });
  console.log('Render:', url, pageSize.width, width);

  const checkSize = useCallback(() => {
    if (canvasRef.current) {
      if (!fit || height <= 0 || width <= 0) {
        return;
      }
      const cWidth = canvasRef.current.width;
      const cHeight = canvasRef.current.height;

      console.log('Sizes', { cWidth, cHeight }, { width, height });
      if (cHeight > height - 36 && cWidth > width) {
        console.error('impossible case...');
      } else if (cHeight > height - 36) {
        console.log('Fit height');
        setPageSize({
          height: height - 36
        });
      } else if (cWidth > width) {
        console.log('Fit width');
        setPageSize({
          width: width
        });
      } else {
        console.log('Fit');
        setPageSize({
          width: width
        });
      }
    }
  }, [height, width, fit]);

  return (
    <div className="slideshow">
      {title && <h1>{title}</h1>}
      <Document
        file={url}
        renderMode="canvas"
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages);
        }}
      >
        <Page
          pageNumber={pageNumber}
          canvasRef={(ref) => {
            canvasRef.current = ref || undefined;
            const ctx = ref?.getContext('2d');
            if (ctx) {
              ctx.fillStyle = 'transparent';
            }
          }}
          onRenderSuccess={checkSize}
          width={fit ? pageSize.width : width}
          height={fit ? pageSize.height : height}
          renderAnnotationLayer={false}
          renderInteractiveForms={false}
          renderTextLayer={false}
        />
      </Document>
      {showToolbar && (numPages || 1) > 1 && (
        <div className="slideshow__toolbar">
          <div className="btn" onClick={() => setPageNumber(1)}>
            <ChevronsLeft size="24px" />
          </div>

          <div className="btn" onClick={() => setPageNumber((current) => current - 1 || 1)}>
            <ChevronLeft size="24px" />
          </div>

          <div className="slideshow__toolbar__pages">
            {pageNumber}/{numPages}
          </div>

          <div
            className="btn"
            onClick={() =>
              setPageNumber((current) => {
                if (current + 1 <= (numPages || 1)) {
                  return current + 1;
                } else {
                  return current;
                }
              })
            }
          >
            <ChevronRight size="24px" />
          </div>

          <div className="btn" onClick={() => setPageNumber(numPages || 1)}>
            <ChevronsRight size="24px" />
          </div>
        </div>
      )}
    </div>
  );
};

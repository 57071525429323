import React from 'react';
import './Features.scss';
import { Smartphone, Database, Map, FileText, Calendar, Layers } from 'react-feather';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

interface IconMeta {
  icon: JSX.Element;
  text: string;
}

const iconMetaList: IconMeta[] = [
  {
    icon: <Database color="#F88360" strokeWidth={1} />,
    text: 'Création et gestion de bases de données.'
  },
  {
    icon: <Map color="#F6B726" strokeWidth={1} />,
    text: 'Interface SIG (visualisation et interaction).'
  },
  {
    icon: <Smartphone color="#2A725F" strokeWidth={1} />,
    text: 'Accès web et mobile à vos données.'
  },
  {
    icon: <FileText color="#446CAC" strokeWidth={1} />,
    text: 'Création de cartes et de rapports sur mesure.'
  },
  {
    icon: <Calendar color="#98AF6F" strokeWidth={1} />,
    text: 'Planification et historique des interventions.'
  },
  {
    icon: <Layers color="#936EC7" strokeWidth={1} />,
    text: 'Numérisation des données dès leur relevé.'
  }
];

// Animation specs
const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2
    }
  },
  hidden: {
    opacity: 0
  }
};

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -100 }
};

const Features = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-20% 0px'
  });

  const controls = useAnimation();

  if (inView) {
    controls.start('visible');
  }

  return (
    <section id="Features" className="Features">
      <div className="features-illustration">
        <h2 className="illustration-title">
          <span className="lighter">
            Conçu pour les
            <br />
          </span>{' '}
          propriétaires et gestionnaires de réseaux ferroviaires et routiers.
        </h2>
      </div>
      <div className="features-list">
        <h1>
          <span className="lighter">
            À votre service.
            <br />
          </span>
          <span>Rigueur de l'ingénieur civil et maîtrise de la technologie.</span>
        </h1>
        {
          <motion.div
            className="feature-icons"
            variants={list}
            initial="hidden"
            animate={controls}
            ref={ref}
          >
            {iconMetaList.map((m, key) => (
              <FeatureIcon iconMeta={m} key={key + 1} />
            ))}
          </motion.div>
        }
        <div className="feature-text">
          <p>
            <b>Nous travaillons dur pour rendre les choses simples.</b> Depuis le recensement des
            données sur mobile ou tablette jusqu'à leur visualisation sur le web, nous proposons une
            solution complète mais facile à utiliser. <br />
            <br />
            Gestio permet de connaître son patrimoine, de planifier des interventions d'entretien ou
            d'assainissement, ou encore de garder la trace des évènements du passé et d'obtenir
            rapidement une vue d'ensemble. Tout simplement.
          </p>
        </div>
      </div>
    </section>
  );
};

// === ICONS

interface FeatureIconProps {
  iconMeta: IconMeta;
}

const FeatureIcon: React.FC<FeatureIconProps> = ({ iconMeta }) => {
  return (
    <motion.div className="FeatureIcon" variants={item}>
      <div className="icon">{iconMeta.icon}</div>
      <p>{iconMeta.text}</p>
    </motion.div>
  );
};

export default Features;

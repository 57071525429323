import { ReactNode, useRef } from 'react';
import Carousel from 'framer-motion-carousel';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import './News.scss';
import TransN from './TransN';
import Mvr from './Mvr';
import Oft from './Oft';
import { CarouselRef } from 'framer-motion-carousel/dist/types/types';
import Lifecyle from './Lifecycle';
import Projection from './Projection';

interface NewsMeta {
  text: ReactNode;
}

const newsList: NewsMeta[] = [
  { text: <Lifecyle /> },
  { text: <Projection /> },
  { text: <Oft /> },
  { text: <Mvr /> },
  { text: <TransN /> }
];

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -100 }
};

const News = () => {
  const { inView } = useInView({
    triggerOnce: true,
    rootMargin: '-20% 0px'
  });

  const controls = useAnimation();

  if (inView) {
    controls.start('visible');
  }
  const carouselRef = useRef<CarouselRef>(null);

  return (
    <section id="News" className="News">
      <div className="news-illustration">
        <h2 className="illustration-title">
          <span className="lighter">
            Maîtriser à tout moment
            <br />
          </span>{' '}
          les différents éléments de votre patrimoine.
        </h2>
      </div>
      <div className="news-list-container">
        <div className="news-list">
          <h1>
            <span className="lighter">
              Nos actualités
              <br />
            </span>
          </h1>
          <div className="carousel-container">
            <Carousel ref={carouselRef} autoPlay interval={7500} loop>
              {newsList.map((m, key) => (
                <NewsComp key={key + 1}>{m.text}</NewsComp>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

// === NEWS

interface NewCompProps {
  children: ReactNode;
}

const NewsComp = ({ children }: NewCompProps) => {
  return (
    <motion.div className="NewsComp" variants={item}>
      {children}
    </motion.div>
  );
};

export default News;

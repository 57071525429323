import './mvr.scss';

import logo from '../../img/MVR.png';

/**
 * Mvr componenent
 */
export default function Mvr(): JSX.Element {
  return (
    <div className="mvr-card" style={{}}>
      <img src={logo} alt="Transports Montreux-Vevey-Riviera" />
      <h1>Le MVR renouvelle sa location de GESTIO en 2024</h1>
    </div>
  );
}

import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useCallback, useId, useState } from 'react';

import { XCircle } from 'react-feather';

import './modal.scss';
/**
 * Modal componenent properties
 */
export interface ModalProps {
  fullscreen?: ReactNode;
  children: ReactNode | ((toggle: () => void) => ReactNode);
}

/**
 * Modal componenent
 */
export default function Modal({ fullscreen, children }: ModalProps): JSX.Element {
  const layoutId = useId();
  const [selected, setSelected] = useState(false);

  const toggleFullscreen = useCallback(() => {
    setSelected((v) => !v);
  }, []);
  const hasRenderProps = typeof children === 'function';

  return (
    <>
      <motion.div
        layoutId={layoutId}
        onClick={hasRenderProps ? toggleFullscreen : toggleFullscreen}
      >
        {hasRenderProps ? children(toggleFullscreen) : children}
      </motion.div>

      <AnimatePresence>
        {selected && (
          <motion.div className="modal-fullscreen" layoutId={layoutId}>
            {fullscreen || (hasRenderProps ? children(toggleFullscreen) : children)}
            <motion.div className="modal__close-button" onClick={() => setSelected(false)}>
              <XCircle size="48px" />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

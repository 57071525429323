import React from 'react';
import './App.scss';
import Header from './components/Header/Header';
import Advantages from './sections/advantages/Advantages';
import Features from './sections/features/Features';
import Footer from './sections/footer/Footer';
import Intro from './sections/intro/Intro';
import News from './sections/news/News';
import Videos from './sections/video/Videos';

function App() {
  return (
    <div className="App">
      <Header />
      <Intro />
      <News />
      <Videos />
      <Features />
      <Advantages />
      <Footer />
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import './Header.scss';
import classNames from 'classnames';

const Header = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const listener = () => {
      setScroll(window.scrollY > 50);
    };
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  const classes = classNames({
    Header,
    hasScrolled: scroll
  });

  return (
    <header className={classes}>
      <div className="Header__logo"></div>
      <nav className="Header__nav"></nav>
    </header>
  );
};

export default Header;

import ResizeObs from '../../components/ResizeObs';
import { SlideShow } from '../../components/SlideShow/SlideShow';

/**
 * TransN componenent
 */
export default function TransN(): JSX.Element {
  return (
    <ResizeObs>
      {(width, height) => <SlideShow url="/transN.pdf" height={height} width={width} fit={false} />}
    </ResizeObs>
  );
}
